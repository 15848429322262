<template>
  <div class="shopDetail">
    <nav-view :titleName="titleName"></nav-view>
    <div class="shopInfo">
      <div class="centerWidth marginAuto flex spacebetween shopInfoCenter">
        <div class="shopInfoCenterLeft flex flexColumn spacebetween">
          <img :src="$store.getters.Baseurl + goodsDetail.banner[showbanner]"
               class="bigImg"
               alt="">
          <div class="bannerList flex spacebetween alignCenter">
            <div class="bannerListLeft flex spacearound alignCenter"
                 @click="transLeft"><i class="el-icon-arrow-left"></i></div>
            <div class="bannerListCenter">
              <div style="white-space:nowrap;width:100%;"
                   class="bannerListCenterin"
                   :style="{transform: `translateX(${transX}px)`}">
                <img class="smallImg"
                     style="cursor: pointer;"
                     v-for="(im,ix) in goodsDetail.banner"
                     @click="showbanner = ix"
                     :key="ix"
                     :src="$store.getters.Baseurl + im"
                     alt="">
              </div>
            </div>
            <div class="bannerListRight flex spacearound alignCenter"
                 @click="transRight"><i class="el-icon-arrow-right"></i></div>
          </div>
        </div>
        <div class="shopInfoCenterRight">
          <div class="shopInfoCenterRightTitle">{{goodsDetail.name}}</div>
          <div class="shopInfoCenterRightTitle">库存数量：{{goodsDetail.stock_num}}</div>
          <div class="shopInfoCenterRightRemark">{{goodsDetail.introduce}}</div>
          <div class="shopInfoCenterRightPrice flex alignCenter">
            <span>物品价格</span>
            <div style="color:#FD9E27;margin-left:25px;">
              <b style="font-size:26px;">{{goodsDetail.integral}}</b> 积分 <b>+</b> <b style="font-size:26px;">{{goodsDetail.price}}</b> 元
            </div>
          </div>
          <div class="shopInfoCenterRightHandle flex">
            <div class="shopNumber flex spacebetween alignCenter">
              <div class="shopNumberLeft flex alignCenter spacearound">
                {{shopNumber}}
              </div>
              <div class="shopNumberRight flex flexColumn spacebetween">
                <span @click="numberAdd">+</span>
                <span @click="numberRemove"
                      style="border:none;">-</span>
              </div>
            </div>
            <button class="stock_num shopBtn"
                    @click="byNow"
                    v-if="shopNumber > goodsDetail.stock_num">库存不足</button>
            <template v-else>
              <button class="addCar shopBtn"
                      @click="addCar">加入购物车</button>
              <button class="nowBay shopBtn"
                      @click="byNow">立即购买</button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="shopdetailHandle">
      <div class="centerWidth marginAuto flex"
           style="height:100%;">
        <div class="tabCheck flex spacearound alignCenter"
             @click="tabindex = 1"
             :class="tabindex == 1 ? 'checkbg' : ''">
          商品介绍
        </div>
        <div class="tabCheck flex spacearound alignCenter"
             @click="tabindex = 2"
             :class="tabindex == 2 ? 'checkbg' : ''">
          商品评价
        </div>
      </div>
    </div>
    <div class="centerWidth marginAuto shopdetailHandleCheck flex ">
      <div style="width:100% ;"
           v-html="goodsDetail.content"
           v-if="tabindex == 1"></div>
      <shop-comment v-else></shop-comment>
    </div>
  </div>
</template>
<script>
import shops from '@/components/shops.vue'
import navView from '@/components/navView.vue'
import shopComment from '@/components/shopcomment.vue'
import { get_goods_detail, add_cart, create_order_pc } from '@/utils/Api/goods'
export default {
  name: 'shopDetail',
  components: { navView, shops, shopComment },
  data () {
    return {
      titleName: '商品详情',
      goodsDetail: [],
      transX: 0,
      showbanner: 0,
      shopNumber: 1,
      tabindex: 1
    }
  },
  created () {
    this.initial()
  },
  methods: {
    async byNow () {
      if (!this.$store.getters.token) {
        this.$store.dispatch('user/loginRedirect', '请在登录后购买')
        // this.$message.warning('请登录后重试！')
        return
      }

      if (this.shopNumber > this.goodsDetail.stock_num) {
        // this.$message.error('库存不够了哦~')
        return
      }

      let params = {
        ...this.goodsDetail,
        num: this.shopNumber
      }

      this.$store.commit('common/SET_SHOPORDER', [params])

      this.goother({ path: '/goodslist/confirmorder', query: { s: 2 } })
      // let res = await create_order_pc()
    },
    async addCar () {
      if (!this.$store.getters.token) {
        this.$store.dispatch('user/loginRedirect', '请在登录后加入购物车')
        // this.$message.warning('请登录后重试！')
        return
      }
      let res = await add_cart({ goods_id: this.goodsDetail.id, num: this.shopNumber })
      if (res.length < 1) {
        this.$message.success('加入购物车成功！')
        this.shopNumber = 1
        this.$store.dispatch('common/getCount')
        return
      }
      this.$message.error('加入购物车失败！')
    },
    async initial () {
      let res = await get_goods_detail({ id: this.$route.query.id })
      this.goodsDetail = res
    },
    transLeft () {
      this.showbanner--
      if (this.showbanner <= 0) {
        this.$message.warning("已经是第一张了！")
        if (this.showbanner <= 0) {
          this.showbanner = 0
        }
        return
      }
      if (this.showbanner < 4) { return }
      this.transX = (this.showbanner - 4) * -62
    },
    transRight () {
      this.showbanner++
      if (this.showbanner > this.goodsDetail.banner.length - 1) {
        this.$message.warning('已经是最后一张了！')
        this.showbanner = this.goodsDetail.banner.length - 1
        return
      }
      if (this.showbanner < 5) { return }
      this.transX = (this.showbanner - 4) * -62
    },
    numberAdd () {
      if (this.shopNumber >= this.goodsDetail.stock_num) {
        this.$message.error('没有库存了哟~')
        return
      }
      this.shopNumber++
    },
    numberRemove () {
      if (this.shopNumber < 2) {
        this.$message.warning('不能再减少了哦~ ')
        return
      }
      this.shopNumber--
    }
  }
}
</script>
<style lang="scss" scoped>
.shopdetailHandleCheck {
  margin-top: 40px;
  min-height: 1100px;
  background: #fff;
  .shopdetailHandleCheckRight {
    width: 330px;
    height: 100%;
    background: #fff;
    .shopdetailHandleCheckRightTitle {
      font-size: 24px;
      color: #2f2f2f;
      margin-top: 35px;
      margin-left: 30px;
      text-align: left;
    }
  }
}
.shopdetailHandle {
  width: 100%;
  height: 66px;
  background: #fff;
  margin-top: 25px;
  .tabCheck {
    width: 150px;
    height: 100%;
    font-size: 16px;
    cursor: pointer;
    color: #9f9f9f;
  }
  .checkbg {
    background: #47d7e3;
    color: #fff !important;
  }
}
.shopBtn {
  width: 160px;
  height: 62px;
  font-size: 26px;
  color: #ffffff;
  margin-left: 10px;
  cursor: pointer;
}
.addCar {
  background: #47d7e3;
}
.nowBay {
  background: #fd9e27;
}
.stock_num {
  background-color: #ccc;
}
.shopDetail {
  background: #f7f8fa;
  .shopInfo {
    overflow: hidden;
    width: 100%;
    background: #fff;
    height: 470px;
    .shopInfoCenter {
      margin-top: 20px;
      height: 100%;
      .shopInfoCenterRight {
        width: 615px;
        height: 100%;
        margin-right: 130px;
        .shopInfoCenterRightHandle {
          width: 615px;
          text-align: left;
          margin-top: 30px;
          .shopNumber {
            width: 101px;
            height: 62px;
            border: 1px solid #ededed;
            user-select: none;
            .shopNumberLeft {
              width: 67px;
              font-size: 34px;
              color: #585858;
              border-right: 1px solid #ededed;
              height: 100%;
            }
            .shopNumberRight {
              flex: 1;
              span {
                cursor: pointer;
                width: 33px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                border-bottom: 1px solid #ededed;
              }
            }
          }
        }
        .shopInfoCenterRightPrice {
          width: 614px;
          height: 67px;
          background: #fff7ed;
          margin-top: 80px;
          span {
            display: inline-block;
            width: 82px;
            height: 27px;
            background: #ff9e1a;
            color: #fff;
            font-size: 16px;
            text-align: center;
            line-height: 27px;
            border-radius: 50px;
            margin-left: 38px;
          }
        }
        .shopInfoCenterRightTitle {
          font-size: 22px;
          color: #464646;
          text-align: left;
          margin-top: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; //行数
          -webkit-box-orient: vertical;
        }
        .shopInfoCenterRightRemark {
          font-size: 15px;
          color: #868686;
          margin-top: 50px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; //行数
          -webkit-box-orient: vertical;
        }
      }
      .shopInfoCenterLeft {
        width: 352px;
        height: calc(100% - 34px);
        .bigImg {
          width: 352px;
          height: 352px;
          border: 1px dashed #969798;
        }
        .bannerListLeft,
        .bannerListRight {
          width: 20px;
          height: 100%;
          font-size: 20px;
          color: #c7c7c7;
          cursor: pointer;
        }
        .bannerListCenter {
          width: 312px;
          height: 100%;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          .bannerListCenterin {
            transition: all 0.2s;
          }
          .smallImg {
            width: 57px;
            height: 57px;
            margin-right: 5px;
          }
        }
        .bannerList {
          height: 57px;
          width: 100%;
        }
      }
    }
  }
}
</style>