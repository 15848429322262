<template>
    <div class="shopcomment">
        <div class="commentFilter marginAuto flex">
            <div class="handleBtn" v-for="(im,ix) in handleList" :key="ix" :class="handleIndex == ix ? 'handlecurr' :'' " @click="handle(ix)">{{im}}</div>
        </div>
        <div v-show="goodsComment[page-1].length > 0" class="commentList flex" v-for="(im,ix) in goodsComment[page-1]" :key="ix">
            <div class="commentListLeft">
                <div class="flex alignCenter">
                    <img style="margin-right:15px;" :src="$store.getters.Baseurl + im.portrait" alt="">
                    <div style="word-break: break-all;width:60px;">
                    {{im.name}}
                    </div>
                </div>
            </div>
            <div class="commentListRight">
                <el-rate v-model="im.stars" disabled></el-rate>
                <div class="commentListRightContent">{{im.content}}</div>
                <div class="commentListRightPic">
                    <img v-for="jm in im.img" :key="jm" :src="$store.getters.Baseurl + jm" alt="">
                </div>
            </div>
        </div>
        <temp-data v-show="goodsComment[page-1].length < 1" :shouwtext="'暂无评论'"></temp-data>
        <el-pagination
        v-if="goodsComment[page-1].length > 0"
        style="margin-top:37px;"
        background
        layout="prev, pager, next"
        :total="goodsCount"
        :page-size="10"
        @current-change="currentChange">
        </el-pagination>
    </div>
</template>
<script>
import { get_goods_comment } from '@/utils/Api/goods'
import tempData from '@/components/tempData.vue'
import confirmOrderVue from '../views/layout/goodsChildren/confirmOrder.vue'
export default {
    name:'shopcomment',
    components:{tempData},
    data(){
        return{
            handleIndex: 0,
            handleList:['全部评价', '好评', '中评', '差评'],
            goodsComment:[],
            goodsCount:0,
            page:1
        }
    },
    mounted(){
        this.initialBefor()
    },
    methods:{
        initialBefor(){
            let params = {
                page: this.page,
                limit: 10,
                id: this.$route.query.id,
                type: this.handleIndex
            }
            this.initial(params)
        },
        initial(params){
            get_goods_comment(params).then(res => {
                console.log(res)
                this.goodsCount = res.count
                this.goodsComment.push(res.list)
            })
        },
        handle(ix){
            this.page = 1
            this.handleIndex = ix
            this.goodsComment = []
            this.goodsCount = 0
            this.initialBefor()
        },
        currentChange(value){
            this.page = value
            this.initialBefor()
            this.scrollTop()
        }
    }
}
</script>
<style lang="scss" scoped>
.commentList{
    width: 100%;
    border-bottom: 1px solid #CECECE;
    margin-top: 30px;
    padding-bottom: 30px;
    .commentListRight{
        flex: 1;
        text-align: left;
        .commentListRightPic{
            width: 615px;
            margin-top: 20px;
            img{
                width: 76px;
                height: 76px;
                border: 1px solid #C9C9C9;
                margin-right: 12px;
                margin-top: 12px;
            }
        }
        .commentListRightContent{
            width: 615px;
            margin-top: 15px;
            font-size: 14px;
            line-height: 21px;
            color: #333333;
            word-break: break-all;
        }
    }
    .commentListLeft{
        width: 140px;
        font-size: 14px;
        color: #333333;
        img{
            width: 47px;
            height: 47px;
            border-radius: 50%;
        }
    }
}
.shopcomment{
    margin-left: 38px;
    .commentFilter{
        width: 803px;
        margin-top: 23px;
        .handleBtn{
            padding: 7px 15px;
            border: 1px solid #EAEAEA;
            margin-right: 16px;
            font-size: 15px;
            color: #333333;
            cursor: pointer;
            user-select: none;
        }
        .handlecurr{
            background: #47D7E3!important;
            color: #fff!important;
            border: 1px solid #47D7E3;
        }
    }
}
</style>